.section9{
    background-image: url("./images/section9Bg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 160vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  /* position: relative; */
}

.section9_inner{
    /* border: 3px solid yellow; */
    width: 70%;
    margin: 0 auto;
    padding: 4rem;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
}

.text_stock9{
    /* width: 60%; */
    /* border: 3px solid lightblue; */
    color: yellow !important;
    margin: 0 auto;
    margin-top: -7rem;
    text-align: center;
    z-index: 0;
  
  }


.founders{
    /* border: 3px solid olive; */
    display: flex;
    flex-direction: column;
    
}

.founders2{
    display: none;
}

.founders div{
    display: flex;
    justify-content: center;
    margin: .7rem 5rem;
    margin-bottom: 2rem;

}



@media screen and (max-width: 640px){
    .section9{
        height:240vh ;
    }

    .section9_inner{
        width: 100%;
        padding: 1rem;
        padding-top: 3rem;
    }

    .founders{
        display: none;
    }

    .founders2{
        /* border: 3px solid olive; */
        display: flex;
        flex-direction: column;
        
    }
    
    .founders2 div{
        display: flex;
        justify-content: center;
        /* margin: .7rem 5rem; */
        width: 100%;
        margin: 1rem auto;

    
    }

  }


  @media screen and (max-width: 500px){

    .section9{
        height: auto;
    }
    .founders2{
      padding-top: 10rem !important ;
        
    }
 
  }

  @media screen and (max-width:470px){
    .founders2{
        padding-top: 3rem !important;
    }
  }