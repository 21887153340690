.section5{
    background-image: url("./images/section5Bg.png"); 
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 160vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
}

.section5_content{
  padding: 3rem;
  padding-top: 4rem;
}

.last_part {
  margin: 3rem auto;
  }
  
.section5_main{
  /* border: 3px solid white; */
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.section5_main p:first-child{
  margin-top: 1rem;
  font-size: 1.3rem;

}

.section5_content .crousel{
  /* border: 3px solid white; */
  width: 120%;
}

.text_stock5{
  /* width: 60%; */
  /* border: 3px solid lightblue; */
  margin: 0 auto;
  margin-top: -8rem;
  text-align: center;
  z-index: -1;

}


.section5_frame{

 
  width: 35%;
   display: flex;
   /* border: 3px solid white; */
   z-index: 10;
   position: absolute;
   top: 23rem;
   /* left: 32rem;
   right: 22rem; */
  
 
}
.section5_frame img{
  width: 20%;
 }

/* .section5_main img{
  width: 50%;
  margin-top: 3rem;
  margin-bottom: -70px;
} */



@media screen and (max-width: 1200px){
  .section5{
    height: 140vh;
  }
 } 


@media screen and (max-width: 950px) {

  .section5{
    height: 130vh;
  }
  .section5_main{
    width: 90%;
    padding: 0 1rem;
  }

  
.section5_main p{
margin-top: 4rem;
width: 130%;
margin-top: 1rem !important;

}

.section5_frame{


width: 50%;
 
 top: 17rem;
 /* left: 32rem;
 right: 22rem; */


}
  
}


@media screen and (max-width: 610px){

  .section5{
    height: 110vh;
  }
  .section5_frame{


    width: 50%;
     
     top: 17rem;
     /* left: 32rem;
     right: 22rem; */
   
   
   }

}

@media screen and (max-width: 550px){

  .section5_main p:first-child{
    /* margin-top: 8rem !important; */
    /* border: 3px solid black; */
  
  }
 
   
  }

  @media screen and (max-width: 510px){
    
       .section5{
        height: 100vh;
        
       }
     .section5_main p{
      margin-top: 1rem !important;
      font-size: 1rem !important;
    
    }
   
  }
