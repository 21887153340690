.stock{
    /* border: 3px solid white; */
    margin: 0 auto;
}
.stockText {

    
        color: yellow;
        -webkit-text-fill-color: yellow; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: .5px;
        -webkit-text-stroke-color: rgb(212, 197, 24);
        -webkit-text-fill-color: rgba(255, 255, 255, 0);
        letter-spacing: 1.5rem;
        font-size: 8rem;
      

}


@media screen and (max-width: 900px){

    .stockText{
        font-size: 6.5rem;
        letter-spacing: 1rem;
    }
  }


  @media screen and (max-width: 650px){

    .stockText{
        font-size: 5rem;
        letter-spacing: 1.5rem;
        padding-top: 3rem;
    }
  }
  
  @media screen and (max-width: 550px){

    .stockText{
       display: none;
    }
  }
