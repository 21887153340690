.section2{
    background-image: url("./images/section2Bg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 118vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  display: flex;
  justify-content: center;
}


.section2_frame{

 width: 70%;
  display: flex;
  /* border: 3px solid white; */
  z-index: 10;
  position: absolute;
  top: 4rem;
  /* left: 10rem;
  right: 10rem; */


}



@media screen and (max-width:900px){
  .section2_frame{
    width: 90%;
  }
}
