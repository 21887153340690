.images{
    /* border: 3px solid black; */
    display: flex;
    flex-direction: row;
    /* flex-basis: 20rem; */
    justify-content: center;
    margin: 5rem auto;
    
}

.images img{
    width: 30%;
    text-align: center;
}

@media screen and (max-width: 900px){
   
    .images img{
        width: 35%;
    }
    
}

@media screen and (max-width: 500px){
    .images{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    .images img{
        width: 80% ;
        margin-bottom: 4.5rem;

    }
}

