.frame{
    /* border: 3px solid red; */
    width: 100%;
    height: 20vh;


}

.frame_inner{
    display: flex;
    justify-content: space-between;
}

.frame_inner img{
    width: 14%;

}

