.threeSteps{
    display: flex;
    flex-direction: column;
    width: 95%;

    /* width: 60%; */
    margin: 0 auto;
    padding: 10% 20%;

}

.step{
    padding: 2.2rem 0;
}

@media screen and (max-width: 1070px) {
    .threeSteps{
        display: flex;
        flex-direction: column;
        width: 90%;
    
        /* width: 60%; */
        margin: 0 auto;
        padding: 13% 10%;
    
    }

}

@media screen and (max-width: 600px) {
    .threeSteps{
        display: flex;
        flex-direction: column;
        width: 100%;
        /* border: 3px solid white; */
    
        /* width: 60%; */
        margin: 0 auto;
        padding: 10% 5%;
    
    }

}