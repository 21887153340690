.my_header{
    background-image: url("./images/headerBg.png"); /* The image used */
  background-color: #0c0b0ba8; /* Used if the image is unavailable */
  height: 123vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  overflow:  hidden;
  /* text-align: center; */
}

#header_video{
    display: none;
}



.nav_bar{
    display: flex;
    width: 100%;
    margin: 0 auto;
    /* border: 3px solid white; */
    /* padding: 2rem; */
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.my_header .bottom{
    display: none;
  
  }


.nav_img{
    width: 85%;
    /* border: 3px solid red; */
}

.nav_img img{
    width: 100%;

}

.nav_btn{
    display: none;
}

.nav_burger{
    display: none;
}

.nav_burger::after{
      
    content: "";
    clear: both;
    display: block;

}

.nav_list{

    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
    width: 60%;
    /* font-weight: bold; */
    /* border: 3px solid white; */
    margin: 1rem 0 ;
    padding-top: .8rem;
    /* margin-left: 3rem;
    margin-right: 3rem; */
    
}

.nav_list ul{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}

.nav_img ul li{
    margin: 1rem ;
    list-style: none !important;
    /* list-style-type: none; */
    
}
.nav_img ul li::marker{
    display: none !important;
    color: yellow;
}

.nav_bar ul li a{
    color: white;
    text-transform: uppercase;
    color: white;
    font-family: 'Staatliches', cursive;
    letter-spacing: 3px;
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: none;
}

.header_links{
 /* border: solid 2px white; */
 /* text-align: center; */
 position: absolute;
 /* top: 75%; */
 bottom: 2rem;
 left: 50%;
 transform: translate(-50%,0%);
 /* bottom: -80% !important; */
 display: flex;
 justify-content: center;
 /* bottom: -rem; */

}

.discord_icon{
    border-radius: 50%;
    width: 20%;
  }
  .discord_icon img{
    /* width: 200%; */
    width: 4%;
    /* margin-top: 10rem; */
    z-index: 200;
    border-radius: 55%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
  }


@media screen and (max-width:1200px){
    .nav_list ul li a{
        font-size: 1.1rem;
    }
    .discord_icon img{
        width: 7%;
        right:2rem
    }


}


@media screen and (max-width:900px){
    .nav_list ul li a{
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: normal;
    }

    .discord_icon img{
        width: 7%;
    }
}


@media screen and (max-width:820px){
 
    .nav_list ul li a{
        font-size: 1rem;
    }
    .discord_icon img{
        right: 2rem;
        width: 8%;
    }
    .my_header .bottom{
        display: block;
        /* width: 500%; */
        margin-bottom: 0px;
        position : absolute;
        
        bottom: 0px;
        z-index: 10;
      
      }
}


@media screen and (max-width:710px){
   .nav_img{
    width: 95%;
   }

   .nav_list{
    width: 80%;
   }
}

@media screen and (max-width:650px){
    
    .my_header{
        background-image: none;
        background-color: transparent;
        height: 100vh;
       }

       .discord_icon img{
        width: 10%;
       }

      #header_video{
        display: block;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        overflow: hidden;
        /* left: 0;
        right: 0;
        top: 0;
        bottom: 0; */
        z-index: -1;
        
      }

      .header_links{
        bottom: 15%;
      }

    .nav_bar{
        /* border: 2px solid white; */
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .nav_img{
        display: none;

    }
    .nav_list{
        display: none;

    }
    .nav_btn{
        display: block;
        margin-left: -2rem;
        margin-top: 2rem;

    }


    .nav_burger{
        display: flex;
        /* border: 3px solid wheat; */
        margin-left: -60rem;
        margin-top: 2rem;
        /* height: 100px; */

        justify-content: space-around;
    }
   
}



@media screen and (max-width:510px){
    .nav_burger{
        
        margin-left: -60rem;
       
    }
    .nav_bar{
        margin-right: 7rem;
    }
    .header_links{
        bottom: 20%;
      }
}


@media screen and (max-width:470px){
    .nav_burger{
        
        margin-left: -50rem;
       
    }
    .discord_icon img{
        /* width: 17%; */
        width: 15%;
        right: 1.5rem;
        /* bottom: 20rem; */
    }
    .header_links{
        bottom: 25%;
      }
    .nav_bar{
        margin-right: 10rem;
    }
}