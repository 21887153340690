.section7{
    background-image: url("./images/section7Bg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 630vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

}
.price_table .price_text{
  margin-top: 10px; 
  color: #FCDD49;
 
}

.price_table .price {

  color: #FCDD49;
}
p{
  margin: 0;
  padding: 0 ;
}

.section7_content{
  padding: 3rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
}
.section7_content p{
  color: white;
  font-size: 1.2rem;
}
.first_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  /* border: 3px solid white; */
  margin: 0 auto;
}


.text_stock7{
  /* width: 60%; */
  /* border: 3px solid lightblue; */
  color: yellow !important;
  margin: 0 auto;
  margin-top: -8rem;
  text-align: center;
  z-index: 0;

}
.text_part{
  width: 35%;
}
.text_part h1{
    color: #FCDD49;
    font-size: 3.7rem;
    /* font-size: 50px; */
    font-family: 'Staatliches', cursive;
    font-weight: 200;
    letter-spacing: 5px;

}
.text_part p{
  /* padding-left: 2rem; */
  text-align: left !important;
  font-size: 1.2rem;
}

.first_div img{
  width: 40%;
}


.div_2{
  display: flex;
  flex-direction: column;
  /* border: 3px solid lightblue; */
  width: 60%;
  margin:  0 auto;
  padding: 2rem;
  /* text-align: center; */
}
.div_2 p{
  /* color: gold; */
  /* padding: 0 1rem; */
  text-align: center;
}

.price_table{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  letter-spacing: 2px;

}

.price_table p{
  /* font-size: 1rem; */
  font-weight: bold;
}

.price_table div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid goldenrod;
  border-radius: 10px;
  margin: 4px 0;
  padding: 0 5px;
  /* vertical-align:middle; */
  /* justify-content: center; */
  /* align-items: center; */
  
}




.div_3{
  display: flex;
  flex-direction: column;
  /* border: 3px solid white; */
  width: 60%;
  margin: 0 auto;
  align-items: center;
  /* text-align: center; */

}

.div_3 img{
  width: 60%;
}
.div_3 .price_table{
  width: 90%;
}



@media screen and (max-width: 1200px){
  .section7{
    height: 600vh;
  }
 } 


@media screen and (max-width: 1080px){

  .section7{
    height:670vh ;
  }
  .first_div{
    width: 100%;

  }

  .div_3, .div_2{
    width: 80%;
    margin: 3.5rem auto;
  }
}


@media screen and (max-width:900px){
  .section7{
    height: 650vh;
  }
}




@media screen and (max-width: 850px){

  .first_div{
    width: 100%;
    margin: 0 auto;

  }
  .first_div img{
    width: 40%;

  }

 .text_part{
  width: 50%;

 }
}


@media screen and (max-width: 650px){

  .section7{
    height: 750vh;
  }
  .price_table div{
    display: flex;
    justify-content: space-between !important;
  }

  .first_div{
    display: flex;
    flex-direction: column;

  }

  .text_part{
    width: 80%;
    margin: 2rem auto;
  
  }

  .first_div p{
    margin: 2rem 0;
    text-align: center;

  }
  
  .first_div img{
    width: 60%;
  }

  .div_2 ,.div_3{
    width: 100%;
  }

  .div_2 img , .div_3 img{
    width: 60%;
    margin-bottom: 3rem;

  }
  .section7_content{
    padding: 2rem 0;
  }

  .price_table div:first-child .price{
    /* margin-right: 0%;
    border: 3px solid white; */
    width: 35%;
  
  }
 
}



@media screen and (max-width: 510px){
  .section7{
    height: auto;
    
  }
  .section7_content{
    padding: 2rem 0;
  }


  .div_3 img ,.first_div img{
    width: 100%;
  }

  .first_div p{
    margin: 0;
    margin-top: 6rem;
    text-align: center;
  }

  .text_part h1{
   
    font-size: 2.3rem;
    /* font-size: 50px; */
    font-family: 'Staatliches', cursive;
    font-weight: 200;
    letter-spacing: 3px;

}
.text_part p{
  /* padding-left: 2rem; */
  text-align: left !important;
  font-size: 1rem !important;
  margin-top: 1rem !important;
}

.price_table p{
  font-size: 1rem !important;
  font-weight: 300;
  color: white;
}


.price_table div{
  /* border: 3px solid black; */
  display: flex;
  text-align: center;
}

/* .price_table p */
.div_2{
  padding-left: 2px;
  padding-right: 2px;
}
.div_3{
  padding-left: 2px;
  padding-right: 2px;
}

.div_3 .price_table{
  padding: 0rem;
}

}


.my_para{
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;
  /* border: 3px solid white; */
  font-size: 1.3rem;
  color: white;
}


.last_para{
  margin: 0 auto;
  text-align: center;
  margin-top: 3rem;
  width: 80%;
  line-height: 2.5rem;
  /* border: 3px solid white; */
  font-size: 1.3rem;
  color: white;

}




@media screen and (max-width: 455px){
  .section7{
    height: auto;
    
  }
}