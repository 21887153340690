.section11{
    background-image: url("./images/section11bg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 180vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  overflow: hidden;
  }

  .bottom{
    /* width: 500%; */
    margin-bottom: 0px;
    position : absolute;
    
    /* bottom: -4px; */
    z-index: 10;

  }

  .section11_inner{
      padding: 4rem;
      /* border: 3px solid wheat; */
      font-family: 'Staatliches', cursive;
  }
  
  .content{
      display: flex;
      flex-direction: column;
      margin-top: 7rem;
      margin-left: 8rem;
      width: 60%;

  }

  .text_stock11{
    /* width: 60%; */
    /* border: 3px solid lightblue; */
    color: yellow !important;
    margin: 0 auto;
    margin-top: -7rem;
    text-align: center;
    z-index: 0;
  
  }
  .content div{
      margin: .7rem 0;
      /* padding: 2px; */
  }
  .content p{
      color: white;
      margin-left: 1rem;
      font-size: 1.2rem;
  }

  .my_div{
      background: linear-gradient(to right, #723712,#CFAB38);
      width: 550px;
      /* height: 50px;
       */
       color: #FCDD49;
       padding: 1rem;
       font-size: 1.5rem;
       letter-spacing: 2px;
       border-radius: 30px;
  }



  @media screen and (max-width: 810px){
    .content{
        /* border: 3px solid white; */
        margin-left: 1rem;
        width: 80%;
    }
  }

 

  @media screen and (max-width: 610px){
    
    .section11_inner{
        padding: 2rem 0;
    }
    .content{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .my_div{
      background: linear-gradient(to right, #723712,#CFAB38);
      width: 420px;
      /* height: 50px;
       */
       color: #FCDD49;
       padding: 1rem;
       font-size: 1.2rem;
       letter-spacing: 2px;
       border-radius: 30px;
  }


  }


  @media screen and (max-width:410px){
    .my_div{
      background: linear-gradient(to right, #723712,#CFAB38);
      width: 300px;
      /* height: 50px;
       */
       color: #FCDD49;
       padding: 1rem;
       font-size: 1rem;
       letter-spacing: 2px;
       border-radius: 30px;
  }
  }