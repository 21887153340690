.roadmap{
  background-image: url("./images/roadmapbg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 370vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  /* width: 100%; */
}


.roadmap_content{
    border: 3xp solid white;
    width: 90%;
    margin: 0 auto;
    padding: 3rem;
    padding-top: 4rem;
}

.roadmap_div{
    display: flex;
     padding: 3rem;
    width: 90%;
    margin: 0 auto;
    padding-top: 15rem;
    /* margin-top: 5rem; */
    align-items: center;
    justify-content: center;
    /* border: 3px solid teal; */
    /* overflow: hidden; */
}

.roadmap_div_2{
    display: none;
}


.roadmap_div img{
    width: 400px;
    margin-bottom: 6rem;


}

.dragon img{
    /* width: 60%; */
    height: 1500px;


    /* border: 3px solid white; */

}

@media screen and (max-width: 1300px){
    .roadmap{
        height: 350vh;

    }
    .roadmap_div{
        padding-top: 7rem; 
    }

}


@media screen and (max-width: 1180px){
.roadmap{
    height: 300vh;
}
.roadmap_div img{
    width: 300px;


}

  }

  @media screen and (max-width: 1100px){
    .roadmap{
        height: 270vh;
    }
  }

  @media screen and (max-width: 920px){

    .roadmap{
        height: 270vh;
    }

    .roadmap_div{
        padding-top: 10rem;
    }
    .dragon img{
        display: none;
        
    
    }
    
      }
    
      
      
  @media screen and (max-width: 610px){

    .roadmap{
        height: 560vh;
    }

    .roadmap_div{
        display: none;
    }

    .roadmap_div_2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
            padding-top: 10rem;
    }

    .roadmap_div_2 img{
        width: 400px;
        margin-bottom: 6rem;

    }
    .dragon img{
        display: none;
        
    
    }
    
      }
  
      @media screen and (max-width: 500px){
        .roadmap{
            height: auto;
            overflow: hidden;
            
        }
      }