.section10{
    background-image: url("./images/section10Bg.png"); /* The image used */
  background-color: #050505e4; /* Used if the image is unavailable */
  height: 110vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  }
  .section10_inner{
      padding: 6rem;
  }

  .partner_div{
    display: flex;
    margin: 5rem auto;
    width: 90%;
    justify-content: space-around;
    align-items:center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .partner_div a{
    width: 20%;
    margin: 2rem;
  }

  .partner_div img{
    width: 100%;
    
  }

  /* .coming{
      text-align: center;
      margin: 0 auto;
      margin-top: -3rem;
      font-weight: 100;
    letter-spacing: 2px;
    font-family: 'Staatliches', cursive;
    letter-spacing: 4px;
    font-size: 5rem;
    color:#FCDD49;
    
    
  } */

  .text_stock10{
    /* width: 60%; */
    /* border: 3px solid lightblue; */
    color: yellow !important;
    margin: 0 auto;
    margin-top: -7rem;
    text-align: center;
    z-index: 0;
  
  }


  /* .section10_frame{

 
    width: 60%;
    margin: 0 auto;
    margin-top: 10rem;
     
     /* left: 32rem;
     right: 22rem; */
   
   
   
     
   @media screen and (max-width: 1400px){
    .section10{
      height: auto;
    }
   } 


   @media screen and (max-width: 1200px){
    /* .section10_frame{
      width: 80%;
    } */
   } 


   @media screen and (max-width: 1100px){
/*     
    .section10_frame{
      width: 80%;
    } */
  }

  @media screen and (max-width: 770px){
    .section10{
      height: auto;
    }

    .partner_div a{
      width: 40%;
      margin: 1rem;
    }
    /* .section10_frame{
      width: 100%;
    } */
    /* .coming{
      font-size: 3rem;
    } */
  }


  @media screen and (max-width: 500px){
    .section10{
      height: auto;
    }

    .section10_inner{
      padding-left: 0;
      padding-right: 0;
    }
  

    /* .section10_frame{
      width: 95%;
      
    } */
  }

