.footer{
    background-image: url("./images/Webpage\ Background\ \(1\).png"); 
  background-color: #050505f1; /* Used if the image is unavailable */
  height: 165vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  position: relative;
}

#background-video {

    width: 100vw;
    height: 100%;

    object-fit: cover;
    /* position: absolute; */
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    /* z-index: -1; */
  }

.footer_content{
    padding: 4rem;
    width: 70%;
    margin: 0 auto;
    /* border: 3px solid black; */
   display: flex;
   flex-direction: column;
   /* justify-content: center; */
   align-items: center;
}


.footer_content .logo_img{
    text-align: center;
}

.footer_content .logo_img img{
    width: 85%;

}

.flex_div{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.flex_div div{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    justify-content: center;
}
.footer_content p{
    color: white;
    text-align: center;
    margin-top: 2rem;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.flex_div div a{
    margin: 2rem 0 !important;
    color: white !important;
    font-size: 3rem;
    font-family: 'Staatliches', cursive;
    letter-spacing: 2px;
    text-decoration: none;
    
    

}
.flex_div img{
    width: 70%;
    height: 70%;
}

.social_links{
    display: flex;
}
.social_links img{
    width: 2rem;
    margin: 2rem 2rem;
}

.footer_nav a{
    font-size: 1rem !important;
    color: #FCDD49 !important;
    margin: 2rem !important;
    text-decoration: none;
   

}

@media screen and (max-width: 1010px) {

    .footer{
        height: 170vh;
    }
    
   
  }

  @media screen and (max-width:900px){
    .footer{
        height: 180vh;
    }
}


@media screen and (max-width:810px){
    .footer_content{
        width: 100%;
        padding: 1rem;
    }


}

@media screen and (max-width:530px){
    .footer_content{
        width: 100%;
        padding: 0;
    }

    .footer_nav{
        width: 100%;
        text-align: center;
    }

    .footer_nav a{
        font-size: .9rem !important;
        color: yellow !important;
        margin: 2rem  .5rem !important;
        justify-content: space-between;
    }

    .social_links{
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .social_links img{
        width: 1.5rem;
        margin: 2rem 1rem;
    }

    .flex_div div a{
        margin: 2rem 0 !important;
        color: white !important;
        font-size: 1rem;
    }

    .flex_div img{
        width: 50%;
        height: 50%;
    }

}


@media screen and (max-width:530px){
    .footer{
        height: 140vh;
        padding: 2rem 0;
    }

    .footer_nav{
        width: 100%;
    }

    .footer_nav a{
        font-size: 0.75rem !important;
        color: #FCDD49 !important;
        margin: 0.7rem !important;
        text-decoration: none;
       
    
    }

    
}



