/* .information{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 3px solid black;
} */


.information{
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.information h2{
    margin: 0%;
    font-size: 1rem;
    color: #FCDD49;
    letter-spacing: 2px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'Staatliches', cursive;
   }

.information p{
    margin: 10px 0%;
    letter-spacing: 2px;
    color: white;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    /* font-size: 15px; */
    text-align: center;
    /* color: yellow; */
   }


.social_img{
    display: flex;
    flex-direction: row;
}

.social_img img{
    width: 15px;
    margin:  0 .4rem;
}
.person{
    width: 10rem;
    border-radius: 50%;
}


@media screen and (max-width: 640px){
    .information p{
       font-size: 0.9rem;
       letter-spacing: 1px;
       }


}