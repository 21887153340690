.section8{
    /* background-image: url("./images/Group\ 49.png"); The image used */
  background-color: #05050590; /* Used if the image is unavailable */
  height: 120vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  overflow: hidden;
  position: relative;
}


#background-video {

  width: 100vw;
  height: 120vh;
  object-fit: cover;
  position: absolute;
  overflow: hidden;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: -1;
}

.bottom{
  /* width: 500%; */
  margin-bottom: 0px;
  position : absolute;
  
  bottom: -4px;
  z-index: 10;

}

.section8_content{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.text_stock8{
  /* width: 60%; */
  /* border: 3px solid lightblue; */
  color: yellow !important;
  margin: 0 auto;
  margin-top: -7rem;
  text-align: center;
  z-index: 0;

}
.stepImg{
  /* border: 3px solid white; */
  margin-top: 3rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.stepImg img{
  width: 95%;
}


.stepImg2{
  display: none;
}


@media screen and (max-width: 1200px){
  .section8{
    padding-top: 2rem;
  }
  .stepImg{
    /* border: 3px solid white; */
    margin-top: 6rem;
   
  }
 } 



@media screen and (max-width: 1100px){

  .stepImg{
    /* border: 3px solid white; */
    width: 100%;
    height: 130%;
   
  }
 
  .stepImg img{
    width: 100%;
    height: 130%;
  }
}

@media screen and (max-width: 900px){
  .section8{
    height: 90vh;

  }

  .stepImg{
    /* border: 3px solid white; */
    width: 100%;
    height: 150%;
    margin-top: 3rem;
   
  }
 
  .stepImg img{
    width: 100%;
    height: 150%;
  }


}

@media screen and (max-width: 750px){
  .stepImg{
    display: none;
  }
  .stepImg2{
    display: block;
    margin-top: 3rem;
    width: 110%;
  

  }
  .stepImg2 img{
    width: 90%;
  }
}



@media screen and (max-width: 510px){
  .section8_content{
    padding:2rem 0%;
    margin: 2rem auto;
  }
  .stepImg2{
    margin: 0 auto;
    margin-top: 2rem;
    width: 110%;
  
  }
}