/* .section01{
  display: none;
} */

.section0{
    /* background-image: url("./images/Group\ 46.png");  */
    /* background: linear-gradient(rgba(209, 207, 207, 0.011), rgba(161, 158, 158, 0.075)); */
  background-color: #05050508; /* Used if the image is unavailable */
  height: 110vh; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  overflow: hidden;
}



#background-video1 {

    width: 100vw;
    /* max-width: 80%; */
    height: 110vh !important;
    object-fit: cover;
    position: absolute;
    overflow: hidden;
    
    /* transform: translate(-50%,-50%); */
    z-index: 0;
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    /* z-index: -1; */
    background-position: center center;
  }


  



  .bottom{
    /* width: 500%; */
    margin-bottom: 0px;
    position : absolute;
    
    bottom: -4px;
    z-index: 10;

  }

  @media screen and (max-width:650px){
    .section0{
        display: none;
    }

  }