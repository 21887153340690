.section3{
    /* background-image: url("./images/Group\ 46.png");  */
    /* background: linear-gradient(rgba(209, 207, 207, 0.011), rgba(161, 158, 158, 0.075)); */
  background-color: #0505058f; /* Used if the image is unavailable */
  height: 140vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  overflow: hidden;
}

#background-video {

  width: 100vw;
  height: 120vh;
  object-fit: cover;
  position: absolute;
  overflow: hidden;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: -1;
}

.bottom{
  /* width: 500%; */
  margin-bottom: 0px;
  position : absolute;
  
  bottom: 0px;
  z-index: 10;

}

.main_content{
  background-image: url("./images/section3Bg.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  flex-direction:  column;
  width: 70%;
  margin: 0 auto;
  position: relative;
  top: 10rem;
    /* position: absolute; */
    /* top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */
    /* border: 3px solid black; */
    text-align: center;
    padding: 5rem;
}

.main_content h1{
  margin-bottom: 2rem;
  font-size: 3.5rem;
  color: #58290C;
  font-family: 'Staatliches', cursive;
  letter-spacing: 4px;
  
}
.main_content p{
  display: flex;
  width: 90%;
  color: #9B7300;
  margin: 0 auto;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}


.scroll-container {
  /* border: 3px solid black; */
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  margin-bottom: 2rem;
}

.scroll-text {
  /* height: 100%; */
  text-align: center;
  font-weight:bold;
  /* letter-spacing: 2px; */
  word-spacing: 3px;
  line-height: 10px;
  
  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  
  -moz-animation: my-animation 5s linear infinite;
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 80s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateY(100%); }
  to { -moz-transform: translateY(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateY(100%); }
  to { -webkit-transform: translateY(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}







@media screen and (max-width: 1300px) {

 .section3{
  height: 140;
 }
  .main_content{
  
    padding: 0 auto;
    width: 90%;
    
   
  }
 
}

@media screen and (max-width: 1010px) {

  .main_content{
  
    width: 80%;
    /* padding: 2rem; */
    margin-top: -6rem;
    height: 100vh;
   
  }
  .main_content p{
    display: flex;
    width: 100%;
    
  }
 
}

@media screen and (max-width: 840px) {

  .main_content{
  
    width: 80%;
    /* padding: 2rem; */
    margin-top: -6rem;
    height: 100vh;
   
  }
  .main_content p{
    display: flex;
    width: 100%;
    
  }
 
}

@media screen and (max-width:710px){
 
  .main_content{
  
    width: 90%;
    
   
  }

  .scroll-text {
    /* height: 100%; */
    text-align: center;
    font-weight:bold;
    /* letter-spacing: 2px; */
    word-spacing: 3px;
    line-height: 10px;
    
    /* animation properties */
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    
    -moz-animation: my-animation 5s linear infinite;
    -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 130s linear infinite;
  }


  /* for Firefox */
@-moz-keyframes my-animation {
    from { -moz-transform: translateY(60%); }
    to { -moz-transform: translateY(-100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateY(60%); }
    to { -webkit-transform: translateY(-100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateY(35%);
      -webkit-transform: translateY(35%);
      transform: translateY(35%);
    }
    to {
      -moz-transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  .main_content h1{
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
}