.bottom{
    width: 100%;
    /* text-align: center; */
    margin-bottom: 0px;
    /* overflow: hidden; */
}



@media screen and (min-width: 1700px){
    /* .section10_frame{
      width: 80%;
    } */
    .bottom img{
        width: 100%;
    }

   } 
