.main_heading{
    text-align: center;
    color: #FCDD49;
    font-size: 3.7rem;
    /* font-size: 50px; */
    font-family: 'Staatliches', cursive;
    font-weight: 200;
    letter-spacing: 5px;
}


@media screen and (max-width: 610px){
    .main_heading{
        font-size: 3rem;
    }
}


@media screen and (max-width:410px){
    .main_heading{
        font-size: 2rem;
    }
  }