.section6{
    /* background-image: url("./images/Webpage\ Background\ \(1\).png"); The image used */
  background-color: #050505a9; /* Used if the image is unavailable */
  height: 140vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  overflow: hidden;
  position: relative;
}
 #background-video {

  width: 100vw;
  height: 140vh !important;
  /* object-fit: cover; */
  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  top: 0;
  bottom: 0;
  z-index: -1;
}

.bottom{
  /* width: 500%; */
  margin-bottom: 0px;
  position : absolute;
  
  bottom: 97px;
  z-index: 10;

}

.section_content{
  /* border: 3px solid red; */
  padding: 4rem;
  text-align: center;

}

.section_content p{
  margin-top: 1rem;
  font-size: 1.3rem;
  color: white;
}

.land_iamges{
  margin: 6rem auto;
}


.text_stock6{
  /* width: 60%; */
  /* border: 3px solid lightblue; */
  margin: 0 auto;
  margin-top: -8rem;
  text-align: center;
  z-index: -1;

}



@media screen and (max-width: 900px){

  .section6{
    height: 130vh;
  }
   
  .section_content{
      padding: 2rem;
  }
  .text_stock6{
    margin-top: -6rem; 
  }
  
}

@media screen and (max-width: 610px){
  .section6{
    height: 120vh;
  }
}

@media screen and (max-width: 550px){
  
.section_content p{
  margin-top: 8rem !important;
  width: 100%;
}
  
}


@media screen and (max-width: 510px){
  .section6{
    height: 230vh;
 background-image: url("./images/Webpage\ Background\ \(1\).png"); 
 background-position: center;
 background-size: cover;
 object-fit: cover;
 background-color: #050505d7
  }

  .section6 #background-video{
    display: none;

  }
  
.section6 p{
  font-size: 1rem !important;
  margin-top: 1rem !important;
}

}

@media screen and (max-width: 400px){
  .section6{
    height: 200vh;

  }
}