/* crouse.l{
    border: 3px solid white;
    width: 140%;
} */



.carousel-control-prev-icon, .carousel-control-next-icon {
    height: 30px;
    width: 40px;
    color: yellow;
    /* margin: 0 3 rem; */
    outline: white;
    /* background-color: rgba(234, 165, 165, 0.3);
    background-size: 50%, 50%; */
    border-radius: 10%;
    /* border: 3px solid black; */
}

 .carousel-control-next-icon  {
    background-image: url("./images/nextarrow.png") !important;
   }

   .carousel-control-prev-icon {
    background-image: url("./images/prearrow.png") !important;

   }





   
  @media screen and (max-width: 900px){
    
    .carousel-control-prev-icon, .carousel-control-next-icon {
        height: 2px;
        width: 3px;
        color: yellow;
        /* margin: 0 3 rem; */
        outline: white;
        /* background-color: rgba(234, 165, 165, 0.3);
        background-size: 50%, 50%; */
        border-radius: 10%;
        /* border: 3px solid black; */
    }
  }

