.section4{
    background-image: url("./images/section4bg.png"); /* The image used */
  background-color: #050505e4;
  height: 180vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  overflow: hidden;
}

.section4_content{
  padding: 5rem 3rem;
}

.section4_main{
  /* border: 3px solid white; */
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.section4_main p:first-child{
  margin-top: 1rem;
  font-size: 1.4rem;

}

.text_stock4{
  width: 60%;
  /* border: 3px solid lightblue; */
  margin: 0 auto;
  margin-top: -8rem;
  text-align: center;
  z-index: -1;

}

.last_part p{
margin: 3rem auto;
font-size: 1.3rem !important;
}

/* .section4_main img{
  width: 50%;
  margin-top: 3rem;
  margin-bottom: -70px;
} */

.crousel{
  /* border: 3px solid white; */
  width: 140%;
  padding: 2rem 0;
}

.section4_frame{

 
    width: 45%;
     display: flex;
     /* border: 3px solid white; */
     z-index: 10;
     position: absolute;
     top: 25rem;
     /* left: 32rem;
     right: 22rem; */
   
   
   }
   .section4_frame img{
    width: 20%;
   }

   @media screen and (max-width: 1200px){
    .section4{
      height: 160vh;
    }
   } 

   @media screen and (max-width: 950px) {

    .section4{
      height: 160vh;
    }
    .section4_main{
      width: 90%;
      padding: 0 1rem;
    }

    
.section4_main p{
  margin-top: 4rem;
  width: 125%;
  margin-top: 1rem !important;

}

.section4_frame{

 
  width: 50%;
   
   top: 22rem;
   /* left: 32rem;
   right: 22rem; */
 
 
 }

    
  }


  @media screen and (max-width: 950px){
    .last_part {
      width: 100% ;
      margin: 3rem auto;
    }
    .last_part p{
      width: 100% ;
      margin: 3rem auto;
    }
  }

  @media screen and (max-width: 710px){
    .section4{
      height: 145vh;

    }
  }
  @media screen and (max-width: 600px){

    .section4{
      height: 110vh;
    }
    .section4_frame{

 
      width: 50%;
       
       top: 17rem;
       /* left: 32rem;
       right: 22rem; */
     
     
     }

  }




  @media screen and (max-width: 550px){

    .section4{
      height: 120vh;;
    }

    .section4_main p:first-child{
      margin-top: 8rem !important;
      /* border: 3px solid black; */
    
    }
    .last_part {
      width: 100% ;
      margin: 3rem auto;
    }
    .last_part p{
      width: 100% ;
      margin: 3rem auto;
    }
   

     
    }


    @media screen and (max-width: 510px){
      .section4_frame{

 
        width: 50%;
         
         top: 23rem;
         /* left: 32rem;
         right: 22rem; */
       
       
       }

       .section4_main p:first-child{
        margin-top: 1rem !important;
        font-size: 1rem;
      
      }
      .section4_main p{
        font-size: 1rem !important;
      }
    }

